import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chapter } from 'src/app/globals/base/chapter';
import { Division } from 'src/app/globals/base/division';
import { Site } from 'src/app/globals/base/site';

export const APPS = [
  {
    label: 'Feed',
    value: false,
    id: 1,
  },
  {
    label: 'Food',
    value: false,
    id: 2,
  },
  {
    label: 'Tech',
    value: false,
    id: 3,
  },
  {
    label: 'Pharma',
    value: false,
    id: 4,
  },
  {
    label: 'Personal-Care',
    value: false,
    id: 5,
  },
  {
    label: 'Sustainability ',
    value: false,
    id: 6,
  },
  {
    label: 'Due Diligence ',
    value: false,
    id: 7,
  },
];

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private divisions = new BehaviorSubject<Division[]>([]);
  divisions$ = this.divisions.asObservable();

  private sites = new BehaviorSubject<Site[]>([]);
  sites$ = this.sites.asObservable();

  private chapters = new BehaviorSubject<Chapter[]>([]);
  chapters$ = this.chapters.asObservable();

  combined$ = combineLatest([
    this.divisions$,
    this.sites$,
    this.chapters$,
  ]).pipe(
    map(([d, s, c]) => {
      return { d, s, c };
    })
  );

  setDivisions(divisions: Division[]) {
    this.divisions.next(divisions);
  }

  setSites(sites: Site[]) {
    this.sites.next(sites);
  }

  setChapters(chapters: Chapter[]) {
    this.chapters.next(chapters);
  }
}
