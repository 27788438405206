import { Injectable } from '@angular/core';
import { Application } from '../base/application';
import { Product, ProductDto } from '../base/product';
import { Question } from '../base/question';
import { IclSmartQApi } from './api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiService: IclSmartQApi) {}

  async get(id: number): Promise<Product | undefined> {
    try {
      const res = await this.apiService.get('/products/' + id);
      const _product: Product = new Product(res);
      return _product;
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async getProductsBySite(id: number): Promise<{ products: Product[]; productsNames: any[] } | undefined> {
    try {
      const res = await this.apiService.get(`/products/productsBySite/${id}`);
      const products = res.products.map((p: Partial<Product>) => new Product(p));
      const productsNames = res.productsNames.map((pName: any) => pName);
      return { products, productsNames };
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async getWithQuestions(id: number) {
    try {
      const res = await this.apiService.get('/products/withQuestions/' + id);

      const _product: Product = new Product(res.product);
      const _questions: Question[] = res.questions.map((q: Partial<Question>) => new Question(q));
      return { product: _product, questions: _questions };
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async getAll(){
    try {
      const records = await this.apiService.get('/products');
      
      const _products: Product[] = records?.map((p: Partial<Product>) => new Product(p));
      return _products;
    } catch (err) {
      console.log(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async add(product: Partial<ProductDto>) {
    try {
      const res = await this.apiService.post('/products', { body: product });
      const _product: Product = new Product(res);
      return _product;
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async importProduct(file: any): Promise<any> {
    try {  
      const res = await this.apiService.importProduct('/products/importProduct', {body: file});
      console.log("Hereee");
      return;
    } catch (err) {
      console.error(err);
    }
  }

  async exportPDF(id: number, pdfData: Partial<any>) {
    try {
      const res = await this.apiService.DownloadPDF(
        '/products/exportPdf/' + id,
        { body: pdfData }
      );      
      const date = new Date();
      const currentDate = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`
      const blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, `${pdfData['company_name']}_${pdfData['product_name']}_${currentDate}.pdf`);
    } catch (err) {
      console.error(err);
    }
  }

  async exportExistPDF(name: string, pdfData: Partial<any>) {
    // 
    try {
      const res = await this.apiService.DownloadPDF(
        `/products/exportExistPdf/${name}.pdf`,
        { body: pdfData }
      );
      if(res.record){
        const a = document.createElement('a');
        a.href = res.record;
        a.target = "_blank"
        a.download = `${name}.pdf`;
        document.body.appendChild(a);
        a.click(); 
        document.body.removeChild(a);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getInvalidAnswers(id: number, pdfData: any) {
    try {
      const res = await this.apiService.post('/products/invalidAnswers/' + id, {
        body: pdfData,
      });
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async edit(product: Partial<ProductDto>, productId: string | number | undefined) {
    try {
      const res = await this.apiService.put('/products/' + productId, {
        body: { ...product },
      });
      const _product: Product = new Product(res);
      return _product;
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }

  async delete(id: number) {
    try {
      const res = await this.apiService.delete('/products/' + id, {});
      const _product: Product = new Product(res);
      return _product;
    } catch (err) {
      console.error(err);
      return undefined; // Ensure a value is always returned
    }
  }
}
